import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { DateTime } from 'luxon';
import { getConfigEnv, jsonParse, sleep } from '@/utils/helpers';
import tasqsListModule from '@/store/modules/tasqsListModule';
import assetsModule from '@/store/modules/assetsModule';
import getWellDataTimeRange from '@/graphql/cleanData/queries/getWellDataTimeRange.graphql';
import getNodeMeta from '@/graphql/cleanData/queries/getNodeMeta.graphql';
import getReferenceTimeRange from '@/graphql/deferment/queries/getReferenceTimeRange.graphql';
import listNodeEventsV2 from '@/graphql/cleanData/queries/listNodeEventsV2.graphql';
import getProcedureResponsesByNodeId from '@/graphql/procedures/queries/getProcedureResponsesByNodeId.graphql';
import getWellCommentHistory from '@/graphql/workflow/queries/getWellCommentHistory.graphql';
import cleanDataApolloClient from '@/lib/appsync/cleanData';
import defermentApolloClient from '@/lib/appsync/deferment';
import proceduresApolloClient from '@/lib/appsync/procedures';
import workflowApolloClient from '@/lib/appsync/workflow';
import isOnline from 'is-online';
import localForage from '@/lib/localForage';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'tasqProductionDataChartModule',
  store,
})
class TasqProductionDataChartModule extends VuexModule {
  productionData: any = {
    dataset: [],
    time: [],
    upperDataTime: [],
    upperDataOilRateTime: [],
    loaded: false,
  };

  chokeSize?: number = null

  adminChartProductionData: any = {};

  productionDataDictKeyValue: any = [];

  selectedSignals: any =  ['boe_rate','oil_rate', 'gas_rate', 'water_rate'];

  showWellEvents = false;

  productionDataDefermentLabeling: any = {
    dataset: [],
    time: [],
    upperDataTime: [],
    upperDataOilRateTime: [],
    loaded: false,
  };

  productionDataDict: any = {
    date: [],
    gas_rate: [],
    nodeid: '',
    oil_rate: [],
    water_rate: [],
    boe_rate: [],
    oil_rate_fc: [],
    water_rate_fc: [],
    gas_rate_fc:  [],
    oil_rate_source:  [],
    water_rate_source:  [],
    gas_rate_source: [],
  }

  defermentType: string = '';

  wellHistory: any = [];

  @Mutation
  setChokeSize(size): void {
    this.chokeSize = size;
  }

   @Mutation
  setProductionDataDictKeyValue(data): void {
    this.productionDataDictKeyValue = data;
  }

  @Mutation
   resetSetModule() {
     this.productionData = {
       dataset: [],
       time: [],
       upperDataTime: [],
       upperDataOilRateTime: [],
       loaded: false,
     };

     this.productionDataDictKeyValue = [];

     this.adminChartProductionData = {};

     this.selectedSignals = [];

     this.productionDataDefermentLabeling = {
       dataset: [],
       time: [],
       upperDataTime: [],
       upperDataOilRateTime: [],
       loaded: false,
     };

     this.productionDataDict = {
       date: [],
       gas_rate: [],
       nodeid: '',
       oil_rate: [],
       water_rate: [],
     };

     this.defermentType = '';
   }

  @Mutation
  selectSignal(signal_name): void {
    if (this.selectedSignals.indexOf(signal_name) > -1) {
      this.selectedSignals.splice(this.selectedSignals.indexOf(signal_name), 1);
    } else {
      this.selectedSignals.push(signal_name);
    }
  }

  @Mutation
  resetProductionDataDict(): void {
    this.productionDataDict = {
      date: [],
      gas_rate: [],
      nodeid: '',
      oil_rate: [],
      water_rate: [],
    };
  }

  @Mutation
  setAdminChartProductionData(data): void {
    this.adminChartProductionData = data;
  }

  @Mutation
  setShowWellEvents(data): void {
    this.showWellEvents = data;
  }

  @Mutation
  resetProductionData(): void {
    this.productionData = {
      data: [],
      time: [],
      loaded: false,
    };
    this.wellHistory = [];
  }

  @Mutation
  setDefermentType(deferment_type): void {
    this.defermentType = deferment_type;
  }

  @Mutation
  setProductionDataDict(data): void {
    this.productionDataDict = data;
  }

  @Mutation
  setProductionData(data): void {
    this.productionData = data;
  }

  @Mutation
  setProductionDataDefermentLabeling(data): void {
    this.productionDataDefermentLabeling = data;
  }

  @Mutation
  resetProductionDataDefermentLabeling(): void {
    this.productionDataDefermentLabeling = {
      dataset: [],
      time: [],
      loaded: false,
    };
  }

  @Mutation
  setWellHistoryData(data): void {
    this.wellHistory = data;
  }

  @Mutation
  resetProdSignalData(): void {
    const signalKeys = ['oil_rate', 'gas_rate', 'water_rate']
    if(getConfigEnv('PRODUCTION_TYPE') === 'boe'){
      this.selectedSignals = ['boe_rate'].concat(signalKeys)
    }else{
      this.selectedSignals = signalKeys;
    }

  }

  @Action
  async getNodeMeta({ assetName }) {
    try {
      const {
        data: {
          get_node_meta: {
            dataset: dataset
          },
        },
      }: any = await cleanDataApolloClient.query({
        query: getNodeMeta,
        variables: {
            operator: getConfigEnv('OPERATOR_PASCALED'),
            nodeid: assetName,
            enabled: true,
            get_children: false
        },
        fetchPolicy: 'network-only',
      });
      this.setChokeSize(
        jsonParse(dataset[0]).choke_size
      )
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  async queryProductionData(data: any = 14): Promise<{}> {
    tasqsListModule.setFromNowBack(280)
    this.resetProdSignalData();
    let wellName = tasqsListModule.activeTasq?.wellName || assetsModule.activeTasq?.wellName;
    let isOfflinePreloading = false;
    let daysLength = 14;
    if (typeof data === 'object') {
      wellName = data.wellName;
      daysLength = data.daysLength;
      isOfflinePreloading = true;
    } else {
      daysLength = data;
    }

    const fromTime = DateTime.utc().minus({ days: daysLength + tasqsListModule.fromNowBack }).startOf('day').toISO()
      .slice(0, -5);
    const toTime = DateTime.utc().minus({ days: tasqsListModule.fromNowBack === 1 ? 0 : tasqsListModule.fromNowBack }).toISO().slice(0, -5);
    if (!wellName) {
      return {};
    }
    tasqsListModule.setChartFromTime(fromTime);

    let rawReferenceData: any = null;

    const {
      data: {
        get_well_test_data_time_range_v2: {
          date: time = [],
          oil_rate: oilData = [],
          water_rate: waterData = [],
          gas_rate: gasData = [],
          boe_rate: boeData = [],
          oil_rate_fc: oilDataFc = [],
          water_rate_fc: waterDataFc = [],
          gas_rate_fc: gasDataFc = [],
          oil_rate_source: oilDataSource = [],
          water_rate_source: waterDataSource = [],
          gas_rate_source: gasDataSource = [],
        },
      },
    } : any = await cleanDataApolloClient.query({
      query: getWellDataTimeRange,
      variables: {
        nodeid: wellName,
        from_date: fromTime,
        to_date: toTime,
        operator: getConfigEnv('OPERATOR_PASCALED'),
        fillna_method: 'zeros',
      },
      fetchPolicy: 'network-only',

    });


    const productionType = getConfigEnv('PRODUCTION_TYPE')
    let description = 'gas_rate'


    if(productionType === 'oil'){
      description = 'oil_rate'
    }

    if(productionType === 'boe'){
      description = 'boe_rate'
    }


      const {
        data: {
          get_reference_prod_time_range: rawReferenceDataResult,
        },
      } : any = await defermentApolloClient.query({
        query: getReferenceTimeRange,
        variables: {
          nodeid: wellName,
          operator: getConfigEnv('OPERATOR_PASCALED'),
          description: description,
          from_time: fromTime,
          to_time: toTime,
        },
        fetchPolicy: 'network-only',
      });
      if (rawReferenceDataResult && rawReferenceDataResult.dataset) {
        rawReferenceData = rawReferenceDataResult;
      }
      this.setDefermentType(rawReferenceData.deferment_type);




    return {
      rawReferenceData: rawReferenceData && rawReferenceData ? rawReferenceData : null,
      referenceDataset: rawReferenceData && rawReferenceData ? rawReferenceData.dataset : null,
      time,
      oilData,
      waterData,
      gasData,
      boeData,
      oilDataFc,
      oilDataSource,
      waterDataFc,
      waterDataSource,
      gasDataFc,
      gasDataSource,



    };
  }

  @Action
  async getProductionDataDefermentLabeling(data: any = 14): Promise<void> {
    const isOnlineApp = navigator.onLine;
    let isOfflinePreloading = false;
    let wellName = tasqsListModule.activeTasq?.wellName || assetsModule.activeTasq?.wellName;
    let daysLength = 14;
    if (typeof data === 'object') {
      wellName = data.wellName;
      daysLength = data.daysLength;
      isOfflinePreloading = true;
    } else {
      daysLength = data;
    }
    // daysLength = 100
    if (!wellName) {
      return;
    }
    let response: any = {};
    response = await this.queryProductionData(daysLength);

    const { rawReferenceData } = response;
    const { rawReferenceDataOilRate } = response;
    const { time } = response;
    const { gasData } = response;
    const { oilData } = response;
    const { waterData } = response;
    const { boeData } = response;
    const referenceDataset = response.referenceDataset ? JSON.parse(response.referenceDataset) : {};

    const referenceDatasetOilRate = response.referenceDatasetOilRate ? JSON.parse(response.referenceDatasetOilRate) : {};

    const upperData = referenceDataset && rawReferenceData ? referenceDataset.Value[rawReferenceData.description.indexOf('upper_thresh')] : [];
    const mainData = referenceDataset && rawReferenceData ? referenceDataset.Value[rawReferenceData.description.indexOf('Reference')]: [];
    const lowerData = referenceDataset && rawReferenceData ? referenceDataset.Value[rawReferenceData.description.indexOf('lower_thresh')] : [];


    const upperDataTime = referenceDataset && rawReferenceData?  referenceDataset.time[rawReferenceData.description.indexOf('upper_thresh')] : [];



      this.setProductionDataDefermentLabeling({
        data: [
          upperData,
          mainData,
          lowerData,
        ],
        gasRate: gasData || [],
        oilRate: oilData || [],
        waterRate: waterData || [],
        boeRate: boeData || [],
        time,
        upperDataTime,
        loaded: true,
        productionDataDict: {
          date: time || [],
          gas_rate: gasData || [],
          nodeid: tasqsListModule.activeTasq?.wellName,
          oil_rate: oilData || [],
          boe_rate: boeData || [],
        }
      });

  }

  @Action
  async getProductionData(data: any = 60): Promise<void> {
    this.resetSetModule();
    await sleep(500);


    const isOnlineApp = navigator.onLine;
    const wellName = tasqsListModule.activeTasq?.wellName || assetsModule.activeTasq?.wellName;

    if (!wellName) {
      return;
    }

    // let wellName = tasqsListModule.activeTasq?.wellName || assetsModule.activeTasq?.wellName

    const response: any = await this.queryProductionData(data);

    const { rawReferenceData } = response;
    const { time } = response;
    const { gasData } = response;
    const { oilData } = response;
    const { waterData } = response;
    const { boeData } = response;
    const { oilDataFc } = response;
    const { oilDataSource } = response;
    const { gasDataFc } = response;
    const { gasDataSource } = response;
    const { waterDataFc } = response;
    const { waterDataSource } = response;

    const referenceDataset = response.referenceDataset ? JSON.parse(response.referenceDataset) : {};

    let targetTime = referenceDataset.time[0];



    const upperData = referenceDataset && rawReferenceData  ? (referenceDataset.Value[rawReferenceData.description.indexOf('upper_thresh')]) : [];
    const mainData = referenceDataset && rawReferenceData ?  (referenceDataset.Value[rawReferenceData.description.indexOf('Reference')]) : [];
    const lowerData = referenceDataset && rawReferenceData ? (referenceDataset.Value[rawReferenceData.description.indexOf('lower_thresh')]) : [];


    const upperDataTime = referenceDataset && rawReferenceData ? referenceDataset.time[rawReferenceData.description.indexOf('upper_thresh')] : [];

    this.setProductionDataDict({
      date: time,
      gas_rate: gasData,
      nodeid: wellName,
      oil_rate: oilData,
      water_rate: waterData,
      boe_rate: boeData,
      water_rate_fc: waterDataFc,
      gas_rate_fc: gasDataFc,
      oil_rate_fc: oilDataFc,
      water_rate_source: waterDataSource,
      gas_rate_source: gasDataSource,
      oil_rate_source: oilDataSource,
    });

    this.setProductionDataDictKeyValue(Object.entries(this.productionDataDict));

    this.setProductionData({
      data: [
        upperData,
        mainData,
        lowerData,

      ],
      time: targetTime,
      upperDataTime,
      loaded: true,
    });
  }

  @Action
  async getWellHistory(data: any = 14) {
    try {
      const isOnlineApp = navigator.onLine;
      let isOfflinePreloading = false;
      let wellName = tasqsListModule.activeTasq?.wellName || assetsModule.activeTasq?.wellName;
      let daysLength = 14;
      if (typeof data === 'object') {
        wellName = data.wellName;
        isOfflinePreloading = true;
        daysLength = data.daysLength;
      } else {
        daysLength = data;
      }

      if (!wellName) {
        return;
      }

      const fromTime = DateTime.utc().minus({ days: daysLength + tasqsListModule.fromNowBack }).toISO().slice(0, -5);
      const toTime = DateTime.utc().minus({ days: tasqsListModule.fromNowBack === 1 ? 0 : tasqsListModule.fromNowBack }).toISO().slice(0, -5);

      if (isOnlineApp) {
        const {
          data: {
            list_node_events_v2: {
              dataset,
            },
          },
        } : any = await cleanDataApolloClient.query({
          query: listNodeEventsV2,
          variables: {
            nodeid: wellName,
            from_time: fromTime,
            to_time: toTime,
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        });

        const {
          data: {
            get_procedure_responses_by_node_id: {
              results: unparsedProcedures,
            },
          },
        } : any = await proceduresApolloClient.query({
          query: getProcedureResponsesByNodeId,
          variables: {
            input: {
              node_id: wellName,
              start_date: fromTime,
              end_date: toTime,
              operator: getConfigEnv('OPERATOR_LOWERCASED'),
            },
          },
        });
        const parsedProceduresEvents = unparsedProcedures.map((i) => JSON.parse(i));

        const {
          data: {
            get_well_comment_history: {
              well_history: rawWellHistory,
            },
          },
        } : any = await workflowApolloClient.query({
          query: getWellCommentHistory,
          variables: {
            node_id: wellName,
            start_date: fromTime,
            GroupBy: {
              Interval: 'Day',
              Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            end_date: toTime,
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        });

        const wellHistory = jsonParse(rawWellHistory?.[0] || null) || [];
        const comments = wellHistory.comments || [];
        let events = [] as any;

        events = dataset ? [...dataset, ...comments, ...parsedProceduresEvents].map((i, idx) => {
          if (i.response_data) {
            const name = i.response_data.user_response ? (i.response_data.user_response || {}).selection || ''
              : (i.response_data.UserResponse || {}).Selection || '';
        const failure = i.response_data.user_response ? i.response_data?.user_response?.selection_details.reason[0] || '' // eslint-disable-line
              : i.response_data?.UserResponse?.SelectionDetails?.Reason[0] || '';
            const yesResponse = name === 'YES';
            return {
              id: idx,
              type: 'Anomaly',
              failure,
              name,
              start: new Date(i.time),
              color: '#ffb01b',
              description: i.comment,
              iconColor: yesResponse ? 'green' : 'red',
              icon: yesResponse ? 'check_circle' : 'cancel',
            };
          }
          if (i.ProcedureID) {
            return {
              id: idx,
              start: new Date(`${i.CreatedTime}Z`),
              type: 'Procedure',
              color: '#8C8FB0',
              description: `Action: ${i.ResponseOption}`,
            };
          }
          const parsed = JSON.parse(i);
      const info = parsed._value || []; // eslint-disable-line
          const setPoint = info[0].Note === 'Setpoint Change';
          return {
            id: idx,
            start: new Date(parsed.time),
            type: setPoint ? 'Setpoint change' : 'Event',
            color: setPoint ? '#BD62FF' : '#0076FF',
            info,
            description: setPoint
          ? `Prior value: ${info[0].PriorValue.toFixed(1) || 'N/A'}, New value: ${info[0].NewValue.toFixed(1) || 'N/A'}` // eslint-disable-line
              : (info || []).map((e) => e.Note).join('. '),
            name: setPoint ? info[0].Description : 'XSPOC alarm',
          };
        }) : [];
        events = events.sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime());
        this.setWellHistoryData(events);
      }
    } catch (error: any) {
      console.error(error.message);
    }
  }
}

export default getModule(TasqProductionDataChartModule);
