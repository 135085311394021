
import { Component, Watch } from "vue-property-decorator";
import Vue from "vue";
import { mixins } from "vue-class-component";
import TasqDetailsMixin from "@/components/tasqsCommon/TasqDetailsMixin";
import tasqsListModule from "@/store/modules/tasqsListModule";
import GenericMixin from "@/lib/mixins/GenericMixin";
import { getComponent } from "@/utils/helpers";
import TasqJob from "@/interfaces/tasqs/TasqJob";
import tasqProductionDataChartModule from "@/store/modules/tasqProductionDataChartModule";
import tasqFeedbackModule from "@/store/modules/tasqFeedbackModule";

@Component({
  components: {
    TasqWellStatusBanner: () => getComponent("tasqs/TasqWellStatusBanner"),
    TasqBatchFeedback: () => getComponent("tasqs/TasqBatchFeedback"),
  },
})
export default class TasqsDetailV2 extends mixins(TasqDetailsMixin, GenericMixin) {
  handleScroll() {
    if (this.$refs.wellSignalViewCy != null) {
      // @ts-ignore
      this.$refs.wellSignalViewCy.handleScroll();
    }

  }

  get disabledDates() {
    let tasqDetailComponent = this;
    return [
      function (date) {
        let startDate = new Date(tasqDetailComponent.action.startDate);
        startDate.setHours(0,0,0,0);
        date.setHours(0,0,0,0);
        return date <= startDate
      }
    ]
  }


  get gridStyles() {
    if (this.showFeedbackMiniView && this.isFeedbackMiniViewHovering) {
      return 'relative grid grid-rows-1';
    }
    if (this.showFeedbackMiniView && !this.isFeedbackMiniViewHovering) {
      return 'tasqs-grid-mini1';
    }
    return 'tasqs-grid';
  }

  get chokeSize() {
    return (
      tasqProductionDataChartModule.chokeSize
    );
  }


  get tasqDataLoadingFeedback(){
    return tasqsListModule.tasqDataLoadingFeedback
  }

  @Watch("$route.params.id", { immediate: true })
  async onTasqRouteIdChange(id: string) {
    const { wellName } = this.$route.query;
    if (id) {
      this.showDataLoadingFeedback = true;

      tasqsListModule.setTasqDataLoadingFeedback(true);



      this.fetchTasq().then(async () => {
        tasqsListModule.updateTasqViewsCount({ predictionID: id });

        tasqProductionDataChartModule.getNodeMeta({ assetName: tasqsListModule.activeTasq.wellName });
        await this.sleep(300)
        tasqsListModule.setTasqDataLoadingFeedback(false);
      });

      this.padWellsSkipToBeginning();
    } else {
      tasqsListModule.setActiveTasq("");
    }
    this.multiTasqType = "";
  }


  prepareAction(action) {
    this.$emit("prepare-action", action);
  }

  prepareWaitingOnStatus() {
    this.$emit("prepare-waiting-on-status");
  }

  setVerticalListPinnedValue() {
    tasqsListModule.setIsVerticalListUnPinned(!tasqsListModule.isVerticalListUnPinned);
  }

  setVerticalListHovering(state) {
    tasqsListModule.setIsVerticalListHovering(state);
  }


  get showFeedbackMiniView(){
    return tasqFeedbackModule.showFeedbackMiniView
  }


  get isFeedbackMiniViewHovering(){
    return tasqFeedbackModule.isFeedbackMiniViewHovering
  }

}
